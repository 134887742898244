import * as s from './styles/globalStyles';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import home from './gallery/home.png';
import b1 from './gallery/1.png';
import b2 from './gallery/2.png';
import b3 from './gallery/3.png';
import b4 from './gallery/4.png';
import b5 from './gallery/5.png';
import b7 from './gallery/7.png';
import b8 from './gallery/8.png';
import b10 from './gallery/10.png';
import b12 from './gallery/12.png';
import b13 from './gallery/13.png';
import b14 from './gallery/14.png';
import b15 from './gallery/15.png';
import b16 from './gallery/16.png';
import b17 from './gallery/17.png';
import b18 from './gallery/18.png';
import b19 from './gallery/19.png';
import b20 from './gallery/20.png';
import b21 from './gallery/21.png';
import b22 from './gallery/22.png';
import b23 from './gallery/23.png';
import b24 from './gallery/24.png';
import b25 from './gallery/25.png';
import b26 from './gallery/26.png';
import b28 from './gallery/28.png';
import b29 from './gallery/29.png';
import b30 from './gallery/30.png';
import b31 from './gallery/31.png';
import b32 from './gallery/32.png';
import b34 from './gallery/34.png';
import b35 from './gallery/35.png';
import b37 from './gallery/37.png';
import b38 from './gallery/38.png';
import b39 from './gallery/39.png';
import b42 from './gallery/42.png';
import b49 from './gallery/49.png';
import b50 from './gallery/50.png';
import b105 from './gallery/105.png';
import b110 from './gallery/110.png';
import b111 from './gallery/111.png';
import b178 from './gallery/178.png';
import b180 from './gallery/180.png';
import b195 from './gallery/195.png';
import b107 from './gallery/107.png';
import b128 from './gallery/128.png';
import b129 from './gallery/129.png';
import b155 from './gallery/155.png';
import b159 from './gallery/159.png';
import b182 from './gallery/182.png';
import b114 from './gallery/114.png';
import b118 from './gallery/118.png';
import b143 from './gallery/143.png';
import b145 from './gallery/145.png';
import b169 from './gallery/169.png';
import b181 from './gallery/181.png';
import b153 from './gallery/153.png';
import b171 from './gallery/171.png';
import b186 from './gallery/186.png';
import b192 from './gallery/192.png';
import b194 from './gallery/194.png';
import b197 from './gallery/197.png';
import b101 from './gallery/101.png';
import b103 from './gallery/103.png';
import b130 from './gallery/130.png';
import b136 from './gallery/136.png';
import b150 from './gallery/150.png';
import b156 from './gallery/156.png';
import b91 from './gallery/91.png';
import b99 from './gallery/99.png';
import b120 from './gallery/120.png';
import b142 from './gallery/142.png';
import b190 from './gallery/190.png';
import b200 from './gallery/200.png';

const StyledImg = styled.img`
  width: 116px;
  height: 116px;
  border: 2px;
  padding: 4px;
  @media (min-width: 767px) {
    width: 240px;
    height: 240px;
    border: 4px;
    padding: 8px;
  }
  @media (min-width: 1401px) {
    width: 360px;
    height: 360px;
    border: 6px;
    padding: 12px;
  }
`;

const StyledHome = styled.img`
  width: 116px;
  height: 116px;
  border: 2px;
  padding: 4px;
  :hover {
    background-color: #c90101;
  }
  @media (min-width: 767px) {
    width: 240px;
    height: 240px;
    border: 4px;
    padding: 8px;
  }
  @media (min-width: 1401px) {
    width: 360px;
    height: 360px;
    border: 6px;
    padding: 12px;
  }
`;

export const Gallery = () => {
  return (
    <s.Screen
      style={{
        backgroundColor: '#181818',
      }}
    >
      <s.Container
        ai={'center'}
        jc={'center'}
        fd={'row'}
        style={{
          flexWrap: 'wrap',
          overflow: 'hidden',
        }}
      >
        <NavLink to="/">
          <StyledHome alt={'home'} src={home} />
        </NavLink>

        <StyledImg alt={'bat'} src={b8} />
        <StyledImg alt={'bat'} src={b14} />
        <StyledImg alt={'bat'} src={b32} />
        <StyledImg alt={'bat'} src={b39} />
        <StyledImg alt={'bat'} src={b42} />
        <StyledImg alt={'bat'} src={b49} />
        <StyledImg alt={'bat'} src={b2} />
        <StyledImg alt={'bat'} src={b10} />
        <StyledImg alt={'bat'} src={b18} />
        <StyledImg alt={'bat'} src={b22} />
        <StyledImg alt={'bat'} src={b26} />
        <StyledImg alt={'bat'} src={b38} />
        <StyledImg alt={'bat'} src={b12} />
        <StyledImg alt={'bat'} src={b24} />
        <StyledImg alt={'bat'} src={b25} />
        <StyledImg alt={'bat'} src={b31} />
        <StyledImg alt={'bat'} src={b34} />
        <StyledImg alt={'bat'} src={b35} />
        <StyledImg alt={'bat'} src={b3} />
        <StyledImg alt={'bat'} src={b7} />
        <StyledImg alt={'bat'} src={b15} />
        <StyledImg alt={'bat'} src={b17} />
        <StyledImg alt={'bat'} src={b23} />
        <StyledImg alt={'bat'} src={b37} />
        <StyledImg alt={'bat'} src={b1} />
        <StyledImg alt={'bat'} src={b5} />
        <StyledImg alt={'bat'} src={b20} />
        <StyledImg alt={'bat'} src={b28} />
        <StyledImg alt={'bat'} src={b29} />
        <StyledImg alt={'bat'} src={b30} />
        <StyledImg alt={'bat'} src={b4} />
        <StyledImg alt={'bat'} src={b13} />
        <StyledImg alt={'bat'} src={b16} />
        <StyledImg alt={'bat'} src={b19} />
        <StyledImg alt={'bat'} src={b21} />
        <StyledImg alt={'bat'} src={b50} />
        <StyledImg alt={'bat'} src={b105} />
        <StyledImg alt={'bat'} src={b110} />
        <StyledImg alt={'bat'} src={b111} />
        <StyledImg alt={'bat'} src={b178} />
        <StyledImg alt={'bat'} src={b180} />
        <StyledImg alt={'bat'} src={b195} />
        <StyledImg alt={'bat'} src={b107} />
        <StyledImg alt={'bat'} src={b128} />
        <StyledImg alt={'bat'} src={b129} />
        <StyledImg alt={'bat'} src={b155} />
        <StyledImg alt={'bat'} src={b182} />
        <StyledImg alt={'bat'} src={b159} />
        <StyledImg alt={'bat'} src={b114} />
        <StyledImg alt={'bat'} src={b118} />
        <StyledImg alt={'bat'} src={b143} />
        <StyledImg alt={'bat'} src={b145} />
        <StyledImg alt={'bat'} src={b169} />
        <StyledImg alt={'bat'} src={b181} />
        <StyledImg alt={'bat'} src={b153} />
        <StyledImg alt={'bat'} src={b171} />
        <StyledImg alt={'bat'} src={b186} />
        <StyledImg alt={'bat'} src={b192} />
        <StyledImg alt={'bat'} src={b194} />
        <StyledImg alt={'bat'} src={b197} />
        <StyledImg alt={'bat'} src={b101} />
        <StyledImg alt={'bat'} src={b103} />
        <StyledImg alt={'bat'} src={b130} />
        <StyledImg alt={'bat'} src={b136} />
        <StyledImg alt={'bat'} src={b150} />
        <StyledImg alt={'bat'} src={b156} />
        <StyledImg alt={'bat'} src={b91} />
        <StyledImg alt={'bat'} src={b99} />
        <StyledImg alt={'bat'} src={b120} />
        <StyledImg alt={'bat'} src={b142} />
        <StyledImg alt={'bat'} src={b190} />
        <StyledImg alt={'bat'} src={b200} />
      </s.Container>
    </s.Screen>
  );
};
