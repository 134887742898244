import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import { Provider } from 'react-redux';
import './styles/reset.css';
import './styles/theme.css';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Goth Party Bats</title>
        <link rel="canonical" href="http://www.gothpartybats.com/" />
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
      </Helmet>
      <App />
    </BrowserRouter>
    ,
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
