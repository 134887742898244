// constants
import Web3 from 'web3';
import SmartContract from '../../contracts/GothPartyBatsTest.json';
// log
import { fetchData } from '../data/dataActions';

const connectRequest = () => {
  return {
    type: 'CONNECTION_REQUEST',
  };
};

const connectSuccess = (payload) => {
  return {
    type: 'CONNECTION_SUCCESS',
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: 'CONNECTION_FAILED',
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: 'UPDATE_ACCOUNT',
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const { ethereum } = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (metamaskIsInstalled) {
      let web3 = new Web3(ethereum);
      try {
        const accounts = await ethereum.request({
          method: 'eth_requestAccounts',
        });
        const networkId = await ethereum.request({
          method: 'net_version',
        });
          if (networkId == 137) {
          const SmartContractObj = new web3.eth.Contract(
            SmartContract.abi,
            "0x48766a6894e2b435F231Ec860fB579496b3A8C6a"
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObj,
              web3: web3,
            })
          );
          // Add listeners start
          ethereum.on('accountsChanged', (accounts) => {
            dispatch(updateAccount(accounts[0]));
          });
          ethereum.on('chainChanged', () => {
            window.location.reload();
          });
          // Add listeners end
        } else {
          dispatch(connectFailed(<span style={{fontSize:'18px', fontStyle:'italic'}}>Change Metamask Network to Polygon</span>));
        }
      } catch (err) {
        dispatch(connectFailed('Something went wrong.'));
      }
    } else {
      dispatch(connectFailed(<span style={{fontSize:'18px', fontStyle:'italic'}}>To Connect First Install <a href="https://metamask.io/" target={'_blank'} rel="noreferrer" color="red">Metamask</a>.</span>));
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};
