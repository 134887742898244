import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from './redux/blockchain/blockchainActions';
import { fetchData } from './redux/data/dataActions';
import { Route, Switch, NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Gallery } from './Gallery';
import RingLoader from 'react-spinners/RingLoader';
import * as s from './styles/globalStyles';
import styled from 'styled-components';
import splashbat from './assets/images/transparentbats.gif';
import twitter from './assets/images/white-twitter.png';
import insta from './assets/images/white-insta.png';
import discord from './assets/images/white-discord.png';

export const StyledButton = styled.button`
  font-size: 32px;
  padding: 10px;
  border-radius: 20px;
  border-width: 2px;
  border-color: white;
  color: white;
  background-color: #f70101;
  cursor: pointer;
  width: 300px;
  height: 68px;
  :disabled {
    cursor: not-allowed;
  }
  :hover {
    background-color: #c90101;
    box-shadow: 0px 0px 16px 0px white;
  }
`;

export const StyledButtonB = styled.button`
  font-size: 32px;
  padding: 10px;
  border-radius: 20px;
  border-width: 2px;
  border-color: white;
  color: white;
  background-color: #f70101;
  cursor: pointer;
  width: 300px;
  height: 68px;

  :disabled {
    cursor: not-allowed;
  }

  :hover {
    background-color: #c90101;
    box-shadow: 0px 0px 16px 0px white;
  }
`;

export const StyledButtonC = styled.button`
  font-size: 22px;
  padding: 10px;
  border-radius: 20px;
  border-width: 2px;
  border-color: white;
  color: white;
  background-color: #f70101;
  cursor: pointer;
  width: 142px;
  height: 68px;
  :disabled {
    cursor: not-allowed;
  }
  :hover {
    background-color: #c90101;
    box-shadow: 0px 0px 16px 0px white;
  }
`;

export const StyledImg = styled.img`
  width: 130px;
  height: 130px;
  @media (min-width: 767px) {
    width: 240px;
    height: 240px;
  }
  @media (min-width: 1401px) {
    width: 360px;
    height: 360px;
  }
`;

export const LogoImg = styled.img`
  width: 60px;
  height: 60px;
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledImgSingle = styled.img`
  width: 300px;
  height: 300px;
  @media (min-width: 767px) {
    width: 600px;
    height: 600px;
  }
  @media (min-width: 1401px) {
    width: 720px;
    height: 720px;
  }
`;

export const FlexHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { width };
};

function App() {
  return (
    <Switch>
      <Route exact path="/" render={() => <AppPage />} />
      <Route exact path="/gallery" render={() => <Gallery />} />
    </Switch>
  );
}

const AppPage = () => {
  const OPENSEA = 'https://opensea.io/collection/goth-party-bats';
  const DISCORD = 'https://discord.gg/aTMaqhHA5H';
  const CONTRACTADDRESS = `0x48766a6894e2b435F231Ec860fB579496b3A8C6a`;
  const PRELAUNCH = false;

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState(
    <span style={{ fontSize: '24px', fontWeight: 800 }}>
      View on{' '}
      <a target={'_blank'} rel="noreferrer" href={OPENSEA}>
        Opensea.io
      </a>
    </span>
  );
  const [claimingNft, setClaimingNft] = useState(false);
  const [claiming6, setClaiming6] = useState(false);
  const [claiming13, setClaiming13] = useState(false);
  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    setFeedback(`Processing... ${_amount}`);
    _amount === 1
      ? setClaimingNft(true)
      : _amount === 6
      ? setClaiming6(true)
      : setClaiming13(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, _amount)
      .send({
        to: CONTRACTADDRESS,
        from: blockchain.account,
        value:
          _amount === 6
            ? blockchain.web3.utils.toWei(
                ((Number(data.cost) / 1e18) * 5).toString(),
                'ether'
              )
            : _amount === 13
            ? blockchain.web3.utils.toWei(
                ((Number(data.cost) / 1e18) * 10).toString(),
                'ether'
              )
            : blockchain.web3.utils.toWei(
                ((Number(data.cost) / 1e18) * _amount).toString(),
                'ether'
              ),
      })
      .once('error', (err) => {
        setClaimingNft(false);
        setClaiming6(false);
        setClaiming13(false);
      })
      .then(() => {
        const x = _amount > 1 ? 's' : '';
        setFeedback(
          <>
            Success! You now own {_amount} Goth Party Bat{x}. Go visit{' '}
            <a target={'_blank'} rel="noreferrer" href={OPENSEA}>
              Opensea.io
            </a>{' '}
            to view it.
          </>
        );
        setClaimingNft(false);
        setClaiming6(false);
        setClaiming13(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  useEffect(() => {
    async function checkConnection() {
      dispatch(connect());
    }
    checkConnection();
  }, []);

  useEffect(() => {
    if (blockchain.account && blockchain.smartContract) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.smartContract, blockchain.account, dispatch]);

  const ConnectButton = () => {
    return (
      <>
        <s.Container flex={1} ai={'center'} jc={'center'}>
          <StyledButtonB
            onClick={(e) => {
              e.preventDefault();
              dispatch(connect());
            }}
          >
            CONNECT
          </StyledButtonB>
          {blockchain.errorMsg ? (
            <s.ErrorDescription style={{ textAlign: 'left', padding: '1em' }}>
              {blockchain.errorMsg}
            </s.ErrorDescription>
          ) : null}
          <s.SpacerMedium />
          <s.TextDescription style={{ fontSize: '24px' }}>
            View on{' '}
            <a target={'_blank'} rel="noreferrer" href={OPENSEA}>
              Opensea.io
            </a>
          </s.TextDescription>
          <s.SpacerMedium />
          <s.TextDescription style={{ fontSize: '24px' }}>
            <a href="#mint">How to Mint</a>
          </s.TextDescription>
        </s.Container>

        <s.SpacerMedium />
      </>
    );
  };

  const FAQ = () => {
    return (
      <s.Container style={{ padding: '32px' }}>
        <s.TextTitle className={'gothLite'}>
          Welcome to the Goth Party Bats Party Cave!
        </s.TextTitle>
        <s.SpacerXSmall />
        <s.TextDescription>
          <span style={{ fontWeight: 900 }}>Goth Party Bats</span> are a
          collection of 6666 unique collectable NFT artworks flying through the{' '}
          <a
            href="https://polygon.technology/"
            style={{ color: 'white' }}
            target={'_blank'}
            rel="noreferrer"
          >
            Polygon
          </a>{' '}
          blockchain.
          <s.SpacerXSmall />
          Summoned from the mines of hell, moonlit overgrown graveyards, mad
          scientist laboratories and echoing halls of decrepit castles, these
          depraved and adorable goth bats have one thing in common - they love
          to P.A.R.T.Y!
          <s.SpacerXSmall />
          Your Goth Party Bat is your date to the dance that gets you into THE
          CAVE and onto the list for invite-only perks.
        </s.TextDescription>

        <s.SpacerLarge />

        <s.TextTitle className={'gothLite'}>
          How are Goth Party Bats created?
        </s.TextTitle>
        <s.SpacerXSmall />
        <s.TextDescription>
          Each <span style={{ fontWeight: 900 }}>Goth Party Bat</span> is
          algorithmically generated from over 200 unique hand draw assets
          including eyes, mouths, hairstyles, outfits, accessories, and more.
          Out of more than 6 billion possible combinations only 6666 NFTs will
          be created. Minting summons a random{' '}
          <span style={{ fontWeight: 900 }}>Goth Party Bat</span> to the
          blockchain and transfers ownership to your wallet.
          <s.SpacerXSmall />
          All bats are goth as hell, but some bats have rarer traits than
          others. Be on the lookout for rare{' '}
          <span style={{ color: '#c3a343', fontWeight: 800 }}>
            {' '}
            gold instruments
          </span>
          , a very rare{' '}
          <span style={{ color: '#c3a343', fontWeight: 800 }}>
            {' '}
            beach goth
          </span>{' '}
          background, or one of only
          <span style={{ color: '#c3a343', fontWeight: 800 }}>
            {' '}
            seven ultra rare gold bats
          </span>
          .
        </s.TextDescription>

        <s.SpacerLarge />

        <div
          style={{
            border: 'solid  2px #ddd',
            margin: '-16px',
            padding: '16px',
            borderRadius: '8px',
          }}
          id="mint"
        >
          <s.TextTitle className={'gothLite'} style={{ color: '#fff' }}>
            How Do I Mint a Goth Party Bat?{' '}
          </s.TextTitle>
          <s.SpacerXSmall />
          <s.TextDescription style={{ color: '#fff' }}>
            1 - Sign up for{' '}
            <a
              href="https://metamask.io/"
              target={'_blank'}
              rel="noreferrer"
              style={{ color: 'white' }}
            >
              Metamask
            </a>
            .
            <s.SpacerXSmall />2 - Add the Polygon Network to your wallet
            (Network ID 137). Search for Polygon on{' '}
            <a
              href="https://chainlist.org/"
              target={'_blank'}
              rel="noreferrer"
              style={{ color: 'white' }}
            >
              Chainlist
            </a>{' '}
            to add with one click.
            <s.SpacerXSmall />
            3A - Purchase Matic directly on the Polygon Network through{' '}
            <a
              href="https://www.moonpay.com/buy/matic"
              target={'_blank'}
              rel="noreferrer"
              style={{ color: 'white' }}
            >
              MoonPay
            </a>{' '}
            or{' '}
            <a
              href="https://global.transak.com/"
              target={'_blank'}
              rel="noreferrer"
              style={{ color: 'white' }}
            >
              Transak
            </a>
            .{' '}
            <span style={{ fontStyle: 'italic' }}>
              not available in all locations{' '}
            </span>
            <s.SpacerXSmall />
            3B - Purchase Matic Tokens on the Ethereum Mainnet at any major
            cryptocurrency exchange: Binance, Kraken, or Coinbase.
            <s.SpacerXSmall />
            Bridge Matic from the Ethereum Mainnet to the Polygon Network with{' '}
            <a
              href="https://wallet.polygon.technology/"
              target={'_blank'}
              rel="noreferrer"
              style={{ color: 'white' }}
            >
              Polygon Wallet
            </a>
            .{' '}
            <span style={{ fontStyle: 'italic' }}>
              {' '}
              Bridging Matic from the Ethereum Mainnet to the Polygon Network
              costs ETH. Bridge all the Matic you'd like to spend in a single
              transaction to avoid repeat bridging fees. Checkout this{' '}
              <a
                href="https://medium.com/stakin/bridging-to-matic-network-from-ethereum-chain-a1b59a64a7ce"
                target={'_blank'}
                rel="noreferrer"
                style={{ color: 'white' }}
              >
                Medium Article
              </a>{' '}
              for more information.
            </span>
            <s.SpacerXSmall />4 -{' '}
            <span style={{ fontWeight: 900 }}>Mint your Goth Party Bats!</span>
          </s.TextDescription>
        </div>
        <s.SpacerLarge />

        <s.TextTitle className={'gothLite'}>Why Polygon?</s.TextTitle>
        <s.SpacerXSmall />
        <s.TextDescription>
          Gas fees on the Ethereum Mainnet are OUT OF CONTROL! It currently
          costs $60-$75 USD to mint a single NFT on the Ethereum Mainnet.
          Polygon is a proof of stake L-2 Sidechain that integrates directly
          with the Ethereum Mainnet but with 1/1000 of the gas cost. Polygon
          integrates directly with OpenSea, the world's largest NFT marketplace.
        </s.TextDescription>
        <s.SpacerLarge />

        <s.TextTitle className={'gothLite'}>
          Who is Behind the Goth Party Bats?
        </s.TextTitle>
        <s.SpacerXSmall />
        <s.TextDescription>
          <a
            href="https://twitter.com/batEFGH/"
            target={'_blank'}
            rel="noreferrer"
            style={{ color: 'white' }}
          >
            EFGH Bat
          </a>{' '}
          and{' '}
          <a
            href="https://twitter.com/the_goth_kevin"
            target={'_blank'}
            rel="noreferrer"
            style={{ color: 'white' }}
          >
            Goth Kevin
          </a>{' '}
          are artists and friends who have been creating art collaboratively in
          the digital and analog worlds since 2008.
        </s.TextDescription>
      </s.Container>
    );
  };

  const Roadmap = () => {
    return (
      <s.Container style={{ padding: '32px' }}>
        <s.TextTitle className={'gothLite'} id={'Roadmap'}>
          Roadmap
        </s.TextTitle>
        <s.SpacerMedium />
        <s.TextTitle className={'gothLite'}>6.66% Minted</s.TextTitle>
        <s.SpacerXSmall />
        <s.TextDescription>
          Party Favors: Partygoers (A.K.A. Goth Party Bats holders) are eligible
          for Bat giveaways.
        </s.TextDescription>
        <s.SpacerMedium />
        <s.TextTitle className={'gothLite'}>10% Minted</s.TextTitle>
        <s.SpacerXSmall />
        <s.TextDescription>
          Hey DJ pump this goth party: Release of shared official Goth Party
          Bats Spotify playlists that all members can add songs to.{' '}
        </s.TextDescription>
        <s.SpacerMedium />
        <s.TextTitle className={'gothLite'}>25% Minted</s.TextTitle>
        <s.SpacerXSmall />
        <s.TextDescription>
          Goth ARTY Bats: Debut the Goth Party Bats community artwork challenge.
        </s.TextDescription>
        <s.SpacerMedium />
        <s.TextTitle className={'gothLite'}>50% Minted</s.TextTitle>
        <s.SpacerXSmall />
        <s.TextDescription>
          Goths Give Back: Establish the Goth Party Bats charitable wing – the
          Goth Party Bats community will decide together where to donate 6.66%
          of the project’s profits.
        </s.TextDescription>
        <s.SpacerMedium />
        <s.TextTitle className={'gothLite'}>75% Minted</s.TextTitle>
        <s.SpacerXSmall />
        <s.TextDescription>
          Exclusive swag: Launch the Goth Party Bats merch line.
        </s.TextDescription>
        <s.SpacerMedium />
        <s.TextTitle className={'gothLite'}>100% Minted</s.TextTitle>
        <s.SpacerXSmall />
        <s.TextDescription>
          The After Party: Goth Party Bats Phase Two – every holder gets a
          complimentary goth party bat companion NFT.
        </s.TextDescription>
        <s.SpacerMedium />
        <s.TextTitle className={'gothLite'}>
          More to come... The Party Won’t stop!
        </s.TextTitle>
      </s.Container>
    );
  };

  const HeaderWrapper = () => {
    const { width } = useViewport();
    const breakpoint = 680;

    return width < breakpoint ? <MobileHeader /> : <DesktopHeader />;
  };

  const DesktopHeader = () => {
    return (
      <>
        <s.SpacerSmall />
        <s.TextHeaderTitle
          className={'goth'}
          style={{
            color: 'var(--dark-grey)',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          <span className={'cap'}>G</span>oth &nbsp;
          <span className={'cap'}>P</span>
          arty &nbsp;<span className={'cap'}>B</span>ats
        </s.TextHeaderTitle>
      </>
    );
  };

  const MobileHeader = () => {
    return (
      <>
        <s.SpacerSmall />

        <s.TextTitle
          className={'goth'}
          style={{ textAlign: 'center', fontSize: '60px', fontWeight: 'bold' }}
        >
          GPB
        </s.TextTitle>
      </>
    );
  };

  const Footer = () => {
    return (
      <FlexHeader style={{ height: '80px' }}>
        <span></span>
        <span
          style={{
            paddingRight: '16px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <a
            href="https://twitter.com/gothpartybats"
            target={'_blank'}
            rel="noreferrer"
          >
            <img alt={'twitter'} src={twitter} height={'48px'} width={'60px'} />
          </a>
          <a
            href="https://www.instagram.com/gothpartybatsnfts/"
            target={'_blank'}
            rel="noreferrer"
          >
            <img alt={'insta'} src={insta} height={'48px'} width={'60px'} />
          </a>
          <a href={DISCORD} target={'_blank'} rel="noreferrer">
            <img alt={'insta'} src={discord} height={'48px'} width={'60px'} />
          </a>
        </span>
      </FlexHeader>
    );
  };
  //single
  const ImageSectionSingle = () => {
    return (
      <s.Container ai={'center'} jc={'center'} fd={'row'}>
        <StyledImgSingle alt={'example'} src={splashbat} />
      </s.Container>
    );
  };

  return (
    <>
      <s.Screen
        style={{
          backgroundImage:
            'linear-gradient(black, #150000, #300000, #550000, #cb0000)',
        }}
      >
        <HeaderWrapper />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          <s.Container flex={1} ai={'center'} jc={'center'}>
            <ImageSectionSingle />
          </s.Container>
          <s.SpacerMedium />
          <s.Container flex={1} jc={'center'} ai={'center'}>
            {PRELAUNCH ? (
              <>
                <s.TextTitle
                  className={'goth'}
                  style={{
                    color: 'var(--dark-grey)',
                    textAlign: 'center',
                    fontSize: '30px',
                  }}
                >
                  Launching Tonight at Midnight EST!!!
                  <s.SpacerSmall />
                  Cost : 50 Matic
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextTitle
                  className={'goth'}
                  style={{
                    color: 'var(--dark-grey)',
                    textAlign: 'center',
                    fontSize: '30px',
                  }}
                >
                  <NavLink to="/gallery">Gallery</NavLink>
                </s.TextTitle>
                <s.SpacerSmall />
                <span
                  style={{
                    paddingRight: '16px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <a
                    href="https://twitter.com/gothpartybats"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <img alt={'twitter'} src={twitter} height={'52px'} />
                  </a>
                  <a
                    href="https://www.instagram.com/gothpartybatsnfts/"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <img alt={'insta'} src={insta} height={'52px'} />
                  </a>
                  <a href={DISCORD} target={'_blank'} rel="noreferrer">
                    <img alt={'insta'} src={discord} height={'52px'} />
                  </a>
                </span>
              </>
            ) : !blockchain.account || !blockchain.smartContract ? (
              <ConnectButton />
            ) : Number(data.totalSupply) === 6666 ? (
              <>
                <s.TextTitle style={{ textAlign: 'center' }}>
                  The sale has ended.
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextDescription style={{ textAlign: 'center' }}>
                  You can still find Goth Party Bats on{' '}
                  <a target={'_blank'} rel="noreferrer" href={OPENSEA}>
                    Opensea.io
                  </a>
                </s.TextDescription>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{
                    textAlign: 'center',
                    fontSize: 40,
                    fontWeight: 'bold',
                  }}
                >
                  {data.totalSupply}/6666
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextTitle style={{ textAlign: 'center' }}>
                  1 Goth Party Bat costs {data.cost / 1e18} MATIC
                </s.TextTitle>
                <s.SpacerMedium />
                <s.Container ai={'center'} jc={'center'} fd={'row'}>
                  <StyledButton
                    disabled={claimingNft || claiming6 || claiming13}
                    onClick={(e) => {
                      e.preventDefault();
                      claimNFTs(1);
                    }}
                  >
                    {claimingNft ? (
                      <RingLoader
                        css={`
                          display: block;
                          margin: 0 auto;
                          border-color: red;
                          z-index: 1000;
                        `}
                        color={'#2b2b2b'}
                        size={33}
                      />
                    ) : (
                      'MINT'
                    )}
                  </StyledButton>
                </s.Container>
                <s.SpacerSmall />

                <s.Container ai={'center'} jc={'center'} fd={'row'}>
                  <StyledButtonC
                    disabled={claimingNft || claiming6 || claiming13}
                    onClick={(e) => {
                      e.preventDefault();
                      claimNFTs(6);
                    }}
                  >
                    {claiming6 ? (
                      <RingLoader
                        css={`
                          display: block;
                          margin: 0 auto;
                          border-color: red;
                          z-index: 1000;
                        `}
                        color={'#2b2b2b'}
                        size={33}
                      />
                    ) : (
                      <>
                        <div>MINT 6</div>
                        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
                          1 FREE
                        </div>
                      </>
                    )}
                  </StyledButtonC>
                  <s.SpacerSmall />

                  <StyledButtonC
                    disabled={claimingNft || claiming6 || claiming13}
                    onClick={(e) => {
                      e.preventDefault();
                      claimNFTs(13);
                    }}
                  >
                    {claiming13 ? (
                      <RingLoader
                        css={`
                          display: block;
                          margin: 0 auto;
                          border-color: red;
                          z-index: 1000;
                        `}
                        color={'#2b2b2b'}
                        size={33}
                      />
                    ) : (
                      <>
                        <div>MINT 13</div>
                        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
                          3 FREE
                        </div>
                      </>
                    )}
                  </StyledButtonC>
                </s.Container>

                <s.SpacerSmall />
                <s.TextDescription
                  style={{ textAlign: 'center', fontSize: 18 }}
                >
                  {feedback}
                </s.TextDescription>
                <s.SpacerLarge />
                <s.Container
                  jc={'center'}
                  ai={'center'}
                  style={{ width: '70%' }}
                >
                  <s.TextDescription
                    style={{ textAlign: 'center', fontSize: 12 }}
                  >
                    Price does not include Gas Fee. Gas Fees are less than $0.01
                    USD on the Matic Network.
                  </s.TextDescription>
                </s.Container>
                <s.SpacerSmall />
              </>
            )}
          </s.Container>
        </ResponsiveWrapper>
        <FAQ />
      </s.Screen>
      <Footer />
    </>
  );
};

export default withRouter(App);
